<template>
  <div>
    <my-nav-bar
      :title="$route.query.store_name"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-sticky offset-top="1.22667rem" class="filter">
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item v-model="listQuery.allow_sales" :options="salesOptions" @change="reload" />
          <van-dropdown-item v-model="listQuery.sort_type" :options="sortOptions" @change="reload" />
        </van-dropdown-menu>
        <van-search v-model="searchKey" placeholder="搜索商品" clearable @search="handleSearch" />
      </van-sticky>
      <van-list ref="list" v-model="loading" :finished="finished" @load="getList">
        <van-empty v-if="showEmpty" description="暂无数据" />
        <div v-for="(goods, index) in list" :key="index" class="goods">
          <finance-goods :goods="goods" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import FinanceGoods from '@/components/FinanceGoods/FinanceGoods'
import { getFinanceReportDetail } from '@/api/finance-report'

export default {
  components: { myNavBar, FinanceGoods },
  data() {
    return {
      searchKey: '',
      list: [],
      listQuery: {
        sort_type: 0,
        allow_sales: 'all',
        search_key: '',
        store_id: this.$route.query.store_id,
        page: 0
      },
      salesOptions: [
        { text: '全部', value: 'all' },
        { text: '允许销售', value: 1 },
        { text: '禁止销售', value: 0 }
      ],
      sortOptions: [
        { text: '默认排序', value: 0 },
        { text: '金额降序', value: 1 },
        { text: '金额升序', value: 2 }
      ],
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  created() {
    if (Number(this.$route.query.type) === 1) {
      this.listQuery.allow_sales = 1
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getFinanceReportDetail(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < 20
        this.showEmpty = this.list.length === 0
      }).catch(() => {
        this.loading = false
        this.finished = true
      })
    },
    reload() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.showEmpty = false
      this.$refs.list.check()
    },
    handleSearch() {
      this.listQuery.search_key = this.searchKey
      this.reload()
    }
  }
}
</script>

<style lang="scss" scoped>

.filter {
  background-color: #fff;
}

.goods {
  margin: 10px;
  background-color: #fff;
}

</style>

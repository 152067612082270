import request from '@/utils/request'

export function getFinanceReportData(params) {
  return request({
    method: 'get',
    url: 'finance-report',
    params
  })
}

export function getFinanceReportDetail(params) {
  return request({
    method: 'get',
    url: 'finance-report/detail',
    params
  })
}

<template>
  <div class="goods">
    <div class="image">
      <van-image :src="goods.product.thumb_image" />
    </div>
    <div class="info">
      <div class="name">{{ goods.product.name }}</div>
      <div class="size">规格：1 * {{ goods.product.size }}</div>
      <div class="quantity">账面库存：{{ goods.book_stock }}{{ goods.product.least_unit }}</div>
      <div class="price">成本单价：￥{{ goods.cost_price }}</div>
      <div class="price">成本总额：￥{{ goods.amount }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goods: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/color.scss';
.goods {
  display: flex;
  padding: 10px;
  background-color: #fff;
}
.image, .image .van-image {
  width: 100px;
  height: 100px;
}
.info {
  margin-left: 10px;
}
.name {
  font-size: 15px;
  word-break: break-all;
}
.size, .storage, .quantity {
  font-size: 13px;
  color: #989898;
}
.price {
  font-size: 13px;
  color: $amountRedColor;
}
</style>

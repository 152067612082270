var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "goods"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.goods.product.thumb_image
    }
  })], 1), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.goods.product.name))]), _c('div', {
    staticClass: "size"
  }, [_vm._v("规格：1 * " + _vm._s(_vm.goods.product.size))]), _c('div', {
    staticClass: "quantity"
  }, [_vm._v("账面库存：" + _vm._s(_vm.goods.book_stock) + _vm._s(_vm.goods.product.least_unit))]), _c('div', {
    staticClass: "price"
  }, [_vm._v("成本单价：￥" + _vm._s(_vm.goods.cost_price))]), _c('div', {
    staticClass: "price"
  }, [_vm._v("成本总额：￥" + _vm._s(_vm.goods.amount))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
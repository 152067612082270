var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": _vm.$route.query.store_name,
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-sticky', {
    staticClass: "filter",
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-dropdown-menu', {
    attrs: {
      "active-color": "#1989fa"
    }
  }, [_c('van-dropdown-item', {
    attrs: {
      "options": _vm.salesOptions
    },
    on: {
      "change": _vm.reload
    },
    model: {
      value: _vm.listQuery.allow_sales,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "allow_sales", $$v);
      },
      expression: "listQuery.allow_sales"
    }
  }), _c('van-dropdown-item', {
    attrs: {
      "options": _vm.sortOptions
    },
    on: {
      "change": _vm.reload
    },
    model: {
      value: _vm.listQuery.sort_type,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "sort_type", $$v);
      },
      expression: "listQuery.sort_type"
    }
  })], 1), _c('van-search', {
    attrs: {
      "placeholder": "搜索商品",
      "clearable": ""
    },
    on: {
      "search": _vm.handleSearch
    },
    model: {
      value: _vm.searchKey,
      callback: function callback($$v) {
        _vm.searchKey = $$v;
      },
      expression: "searchKey"
    }
  })], 1), _c('van-list', {
    ref: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (goods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods"
    }, [_c('finance-goods', {
      attrs: {
        "goods": goods
      }
    })], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }